import { Link } from 'gatsby';

import { useAccessibility, usePageByAlias } from '../../hooks';
import { LogoIcon } from '../../icons';

import styles from './logo.module.scss';

export const Logo = () => {
  const accessibility = useAccessibility();
  const homePage = usePageByAlias(`home`);

  return (
    <Link
      className={styles.wrapper}
      to={homePage?.path || `/`}
      aria-label={accessibility?.backToHome || undefined}
    >
      <LogoIcon className={styles.icon} aria-label={accessibility?.brandName} />
    </Link>
  );
};
