import { useLocale } from '../contexts';

export const useLocalizedFrontmatter: (
  results: Queries.Query,
) => Queries.MarkdownRemarkFrontmatter | null = (results) => {
  const { locale } = useLocale();
  const {
    allMarkdownRemark: { edges },
  } = results;

  const { node: { frontmatter = null } = {} } =
    edges?.find(
      ({ node: { frontmatter } }) => frontmatter?.locale === locale,
    ) || {};

  return frontmatter;
};
