import { type ComponentProps, forwardRef, HTMLAttributes } from 'react';

import { RovingIndexContainer } from '../../components';
import { useClassName, useForwardedRef } from '../../hooks';
import type { Page } from '../../hooks';

import { NavItem } from './nav-item';
import styles from './nav-items.module.scss';

export type Props = HTMLAttributes<HTMLUListElement> & {
  pages: Page[];
  direction?: ComponentProps<typeof RovingIndexContainer>['direction'];
};

export const NavItems = forwardRef<HTMLUListElement, Props>(function NavItems(
  { pages, className, direction, ...navItems }: Props,
  forwardedRef,
) {
  const parentRef = useForwardedRef(forwardedRef);
  const wrapperClassName = useClassName([styles.wrapper, className]);

  return (
    <RovingIndexContainer ref={parentRef} direction={direction}>
      {(rovingContainer) => (
        <ul {...navItems} {...rovingContainer} className={wrapperClassName}>
          {pages.map((page) => (
            <li role="none" className={styles.item} key={page.alias}>
              <NavItem page={page} />
            </li>
          ))}
        </ul>
      )}
    </RovingIndexContainer>
  );
});
