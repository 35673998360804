import { useLayoutEffect, useState } from 'react';

export const useMatchMedia = (expressions: string[]): boolean[] => {
  const [matches, setMatches] = useState<boolean[]>(() =>
    Array.from({ length: expressions.length }).map(() => false),
  );

  useLayoutEffect(() => {
    const teardowns = expressions.map((query, index) => {
      const mql = window.matchMedia(query);

      const handler = () =>
        setMatches((oldMatches) => {
          const newMatches = [...oldMatches];
          newMatches[index] = mql.matches;
          return newMatches;
        });

      handler();
      mql.addEventListener(`change`, handler);

      return () => mql.removeEventListener(`change`, handler);
    });

    return () => teardowns.forEach((teardown) => teardown());
  }, [expressions]);

  return matches;
};
