import { forwardRef } from 'react';
import type { HTMLAttributes } from 'react';

import { useClassName } from '../../hooks';

import styles from './menu-toggle.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {
  isExpanded: boolean;
};

export const MenuToggle = forwardRef<HTMLButtonElement, Props>(
  ({ isExpanded, ...domProps }: Props, ref) => {
    const className = useClassName([
      styles.wrapper,
      isExpanded ? styles.expanded : ``,
    ]);

    return (
      <button {...domProps} className={className} ref={ref}>
        <svg
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
          aria-hidden={true}
        >
          <g className={styles.open}>
            <line className={styles.line1} x1="0" y1="12" x2="48" y2="12" />
            <line className={styles.line2} x1="0" y1="36" x2="48" y2="36" />
          </g>
          <g className={styles.close}>
            <line className={styles.line1} x1="0" y1="24" x2="48" y2="24" />
            <line className={styles.line2} x1="0" y1="24" x2="48" y2="24" />
          </g>
        </svg>
      </button>
    );
  },
);

MenuToggle.displayName = `MenuToggle`;
