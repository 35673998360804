import { Link } from 'gatsby';
import type { HTMLAttributes } from 'react';

import { Text, RovingIndexItem } from '../../components/';
import { Page, useClassName, useMenuItem } from '../../hooks';

import styles from './nav-item.module.scss';

type Props = HTMLAttributes<HTMLUListElement> & {
  page: Page;
};

export const NavItem = ({
  page: {
    path,
    meta: { title },
  },
  className,
}: Props) => {
  const menuItem = useMenuItem();
  const wrapperClassName = useClassName([styles.wrapper, className]);

  return (
    <RovingIndexItem>
      {(rovingItem) => (
        <Link
          {...menuItem}
          {...rovingItem}
          to={path}
          className={wrapperClassName}
          activeClassName={styles.active}
        >
          <Text size="fs-1" wrapper="span">
            {title}
          </Text>
        </Link>
      )}
    </RovingIndexItem>
  );
};
