import { graphql, useStaticQuery } from 'gatsby';

import { PageContext } from '../../config';
import { isPageContext, isString } from '../../utils';

export const MENU = {
  MAIN: `main`,
  MISC: `misc`,
} as const;

export type Menu = (typeof MENU)[keyof typeof MENU];

export type Page = PageContext & {
  path: string;
  menu: Menu[];
};

const menuMap = Object.values(MENU).reduce(
  (map, menu) => ({ ...map, [menu]: true }),
  {} as Record<Menu, boolean>,
);

const isPage = (value: unknown): value is Page =>
  isPageContext(value) &&
  `path` in value &&
  isString(value.path) &&
  `menu` in value &&
  Array.isArray(value.menu) &&
  value.menu.every((item) => item in menuMap);

export const usePages = (): Page[] => {
  const results = useStaticQuery<Queries.Query>(query);
  const edges = results?.allMarkdownRemark?.edges || [];

  return edges.reduce((array, { node: { id, frontmatter } }) => {
    const page = { ...frontmatter, id };
    return isPage(page) ? [...array, page] : array;
  }, [] as Page[]);
};

export const usePagesByMenu = (value: Menu) =>
  usePages().filter(({ menu }) => menu.includes(value));

export const usePageByAlias = (value: string) =>
  usePages().find(({ alias }) => value === alias);

const query = graphql`
  query usePagesQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "page" } } }
      sort: { frontmatter: { rank: ASC } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            menu
            alias
            locale
            meta {
              title
              description
            }
          }
        }
      }
    }
  }
`;
