import { useAccessibility, useMenu, usePagesByMenu } from '../../hooks';

import { NavItems } from './nav-items';

export const Nav = () => {
  const accessibility = useAccessibility();
  const pages = usePagesByMenu(`misc`);

  const menuProps = useMenu({
    orientation: `horizontal`,
    label: accessibility?.menuFooterLabel,
  });

  return <NavItems {...menuProps} pages={pages} />;
};
