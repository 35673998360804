import { Script } from 'gatsby';
import { Fragment, useEffect } from 'react';

import { useLocale } from '../hooks';
import { isFunction, isObject } from '../utils';

type Props = {
  pathname: string;
};

type COOKIEBOT_API = {
  runScripts: () => void;
  setLanguage: (language: string) => void;
};

type PUSHPANDA_API = {
  setLanguage: (language: string) => void;
};

const Cookiebot: COOKIEBOT_API = {
  runScripts: () => {
    if (
      `Cookiebot` in window &&
      isObject(window.Cookiebot) &&
      `runScripts` in window.Cookiebot &&
      isFunction(window.Cookiebot.runScripts)
    ) {
      window.Cookiebot.runScripts();
    }
  },
  setLanguage: (language) => {
    const element = document.getElementById(`Cookiebot`);
    if (!element) {
      return;
    }

    element.dataset.culture = language;
  },
};

const PushPanda: PUSHPANDA_API = {
  setLanguage: (language: string) => {
    // @todo
  },
};

export const Scripts = ({ pathname }: Props) => {
  const { locale } = useLocale();

  useEffect(() => {
    Cookiebot.runScripts();
  }, [pathname]);

  useEffect(() => {
    Cookiebot.setLanguage(locale);
    PushPanda.setLanguage(locale);
  }, [locale]);

  return process.env.GATSBY_ENV === `production` ? (
    <Fragment>
      {/*<Script id="PushPanda">{`*/}
      {/*  var _pushpanda = _pushpanda || [];*/}
      {/*  _pushpanda.push(['_project', 'dfd003a0-1c73-11e8-9c3c-29066e4dae29']);*/}
      {/*  _pushpanda.push(['_language', '${locale}']);*/}
      {/*`}</Script>*/}
      {/*<Script*/}
      {/*  src="https://consent.cookiebot.com/uc.js"*/}
      {/*  data-cbid="0f2752b5-4aab-4a4a-927e-7605c1cfd22b"*/}
      {/*  data-culture={locale}*/}
      {/*/>*/}
      {/*<Script*/}
      {/*  src="https://cdn.pushpanda.io/sdk/sdk.js"*/}
      {/*  strategy="idle"*/}
      {/*  type="text/plain"*/}
      {/*  data-cookieconsent="perferences"*/}
      {/*/>*/}
    </Fragment>
  ) : null;
};
