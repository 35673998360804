import type { AriaAttributes, HTMLAttributes } from 'react';

import type { Nullable } from '../../types';

type BaseProps = {
  orientation?: AriaAttributes['aria-orientation'];
  type?: Extract<HTMLAttributes<HTMLElement>['role'], 'menu' | 'menubar'>;
};

export type Props = BaseProps &
  (
    | {
        label: Nullable<string>;
        labelId?: never;
      }
    | {
        label?: never;
        labelId: string;
      }
  );

export type Result = HTMLAttributes<HTMLElement>;

export const useMenu = ({
  type = `menu`,
  label = undefined,
  labelId = undefined,
  orientation = `vertical`,
}: Props): Result => {
  return {
    role: type,
    'aria-label': label || undefined,
    'aria-labelledby': labelId,
    'aria-orientation': orientation,
  };
};
