import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { useClassName } from '../../hooks';
import type { Breakpoint } from '../../types';

import styles from './section.module.scss';

export type Props = {
  children: ReactNode;
  size?: Extract<Breakpoint, 'sm' | 'md' | 'lg'>;
  color?: `transparent` | `black` | `white` | `primary` | `secondary`;
  paddingTop?: number;
  paddingBottom?: number;
  className?: string;
} & HTMLAttributes<HTMLElement>;

export const Section = forwardRef<HTMLElement, Props>(
  function ForwardRefSection(
    {
      children,
      size = `md`,
      color = `transparent`,
      className = undefined,
      paddingTop = undefined,
      paddingBottom = undefined,
      ...props
    }: Props,
    ref,
  ) {
    const wrapperClassName = useClassName([
      styles.wrapper,
      styles[color],
      styles[size],
      className,
    ]);
    const style = { paddingBottom, paddingTop };

    return (
      <section {...props} ref={ref} className={wrapperClassName} style={style}>
        {children}
      </section>
    );
  },
);
