import { graphql, useStaticQuery } from 'gatsby';

import { useLocalizedFrontmatter } from './use-localized-frontmatter';

export const useAccessibility: () => Queries.MarkdownRemarkFrontmatterA11y | null =
  () => {
    const results = useStaticQuery<Queries.Query>(query);
    const frontmatter = useLocalizedFrontmatter(results);

    return frontmatter?.a11y || null;
  };

const query = graphql`
  query useAccessibilityQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "a11y" } } }) {
      edges {
        node {
          frontmatter {
            locale
            a11y {
              brandName
              companyName
              notfoundTitle
              notfoundMessage
              menuToggleOpen
              menuToggleClose
              backToHome
              backToOverview
              menuMainLabel
              menuFooterLabel
              menuSkipToContent
              quickInfoMenu
              moreDetails
              slide
              slides
              slidePrev
              slideNext
              validationMessage
              hideMessage
              close
              cancel
              confirm
              openFullscreen
              closeFullscreen
              shrink
              enlarge
              cookiesPlaceholder
              cookiesButton
              new
              languages {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;
