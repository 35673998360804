import type { PageProps } from 'gatsby';
import { FunctionComponent, useEffect, useState } from 'react';
import type { ReactElement } from 'react';

import { SM } from '../components';
import type { PageContext, PageData } from '../config';
import { useKeyPress, useLocale, useMatchMedia } from '../hooks';

import { Footer } from './footer';
import { Header } from './header';
import styles from './layout.module.scss';
import { Scripts } from './scripts';

const MATCH_MEDIA = [`(min-width: ${SM}px)`];

type Props = Omit<PageProps<PageData, PageContext>, 'children'> & {
  children: ReactElement;
  teaser?: FunctionComponent<
    Pick<PageProps<PageData, PageContext>, 'data' | 'pageContext'>
  >;
};

export const Layout = ({
  data,
  location,
  pageContext,
  children,
  teaser: Teaser = () => null,
}: Props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isEscapePressed = useKeyPress(`Escape`);
  const [isSM] = useMatchMedia(MATCH_MEDIA);
  const { setLocale } = useLocale();

  const toggleMenuHandler = () => {
    setIsMenuVisible((isMenuVisible) => !isMenuVisible);
  };

  useEffect(() => {
    if (isEscapePressed) {
      setIsMenuVisible(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    setIsMenuVisible(false);
  }, [location.href]);

  useEffect(() => {
    if (isSM) {
      setIsMenuVisible(false);
    }
  }, [isSM]);

  useEffect(() => {
    setLocale(pageContext.locale);
  }, [setLocale, pageContext.locale]);

  return (
    <div className={styles.wrapper}>
      <Teaser data={data} pageContext={pageContext} />
      <Header
        isMenuVisible={isMenuVisible}
        onToggleMenu={toggleMenuHandler}
        direction={isSM ? `horizontal` : `vertical`}
      />
      <main className={styles.main}>{children}</main>
      <Footer />
      <Scripts pathname={location.pathname} />
    </div>
  );
};
