import type { HeadProps } from 'gatsby';
import { Fragment } from 'react';
import type { ReactNode } from 'react';

import type { PageContext, PageData } from '../../config';
import { useAccessibility } from '../../hooks';

type Props = HeadProps<PageData, PageContext> & {
  children?: ReactNode;
};

export function PageHead({ pageContext, children }: Props) {
  const accessibility = useAccessibility();
  const { locale, meta } = pageContext;

  const title = [meta.title, accessibility?.brandName].join(` | `);
  const { description } = meta;

  return (
    <Fragment>
      <html key="html" lang={locale} />
      <title key="title">{title}</title>
      <meta key="robots" name="robots" content="index, follow" />
      <meta key="description" name="description" content={description} />
      <meta key="og:locale" name="og:locale" content={locale} />
      <meta key="og:type" name="og:type" content="website" />
      <meta key="og:title" name="og:title" content={title} />
      <meta key="og:description" name="og:description" content={description} />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta
        key="format-detection"
        name="format-detection"
        content="telephone=no"
      />
      {children}
    </Fragment>
  );
}
