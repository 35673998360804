export const LOCALES = {
  DE: `de`,
  EN: `en`,
} as const;

export type Locales = typeof LOCALES;
export type Locale = Locales[keyof Locales];

export const LOCALES_ARRAY = Object.values(LOCALES) as Locale[];
export const QUERY_LOCALES = LOCALES_ARRAY.join(`\n`);
export const DEFAULT_LOCALE: Locale = LOCALES.DE;

export type PageData = Queries.Query;
export type PageContext = {
  id: string;
  alias: string;
  locale: Locale;
  meta: {
    title: string;
    description: string;
  };
};
