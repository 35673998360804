import { Locale, LOCALES, PageContext } from '../config';

export function isUndefined(value: unknown): value is undefined {
  return typeof value === `undefined`;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === `boolean`;
}

export function isNumber(value: unknown): value is number {
  return typeof value === `number`;
}

export function isString(value: unknown): value is string {
  return typeof value === `string`;
}

export function isSymbol(value: unknown): value is symbol {
  return typeof value === `symbol`;
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isFunction<T extends (...args: any[]) => any>(
  value: T | unknown,
): value is T {
  return typeof value === `function`;
}

export function isObject<T extends object>(
  value: T | unknown,
): value is NonNullable<T> {
  return !isNull(value) && typeof value === `object`;
}

export function isRecord<T extends Record<PropertyKey, unknown>>(
  value: T | unknown,
): value is T {
  return isObject(value) && !isArray(value);
}

export function isArray<T>(value: Array<T> | unknown): value is Array<T> {
  return Array.isArray(value);
}

export function isNode(value: unknown): value is Node {
  return isObject(value) && `nodeType` in value;
}

export function isElement(value: unknown): value is HTMLElement {
  return value instanceof HTMLElement;
}

export const isLocale = (value: unknown): value is Locale =>
  isString(value) && value.toUpperCase() in LOCALES;

export const isPageContext = (value: unknown): value is PageContext =>
  isRecord(value) &&
  `id` in value &&
  isString(value.id) &&
  `alias` in value &&
  isString(value.alias) &&
  `locale` in value &&
  isLocale(value.locale) &&
  isRecord(value.meta) &&
  `title` in value.meta &&
  isString(value.meta.title) &&
  `description` in value.meta &&
  isString(value.meta.description);
