import { ReactNode, useEffect } from 'react';
import type { ComponentProps } from 'react';

import { Container } from '../components';
import {
  useAccessibility,
  useDisclosure,
  useScrollLock,
  useClassName,
  useTransition,
} from '../hooks';

import { Logo } from './header/logo';
import { MenuToggle } from './header/menu-toggle';
import { Nav } from './header/nav';
import styles from './header.module.scss';

type Props = {
  isMenuVisible: boolean;
  onToggleMenu: () => void;
  direction: ComponentProps<typeof Nav>['direction'];
};

export const Header = ({ isMenuVisible, onToggleMenu, direction }: Props) => {
  const [menuTransitionState, toggleMenuTransition] = useTransition({
    timeout: 300,
    preEnter: true,
  });

  const accessibility = useAccessibility();

  const wrapperClassName = useClassName([
    styles.wrapper,
    styles[menuTransitionState],
  ]);

  const { triggerProps: menuTriggerProps, controlProps: menuControlProps } =
    useDisclosure({
      controlExpandedClassName: useClassName([
        styles.menu,
        styles[menuTransitionState],
      ]),
      controlClassName: useClassName([
        styles.menu,
        styles[menuTransitionState],
      ]),
      isExpanded: isMenuVisible,
      triggerId: `menu-toggle`,
      onToggle: onToggleMenu,
      label: isMenuVisible
        ? accessibility?.menuToggleClose
        : accessibility?.menuToggleOpen,
      type: `menu`,
    });

  useEffect(() => {
    toggleMenuTransition(isMenuVisible);
  }, [toggleMenuTransition, isMenuVisible]);

  useScrollLock({ isLocked: isMenuVisible });

  return (
    <header className={wrapperClassName}>
      <Container>
        <div className={styles.navbar}>
          <div className={styles.toggle}>
            <MenuToggle {...menuTriggerProps} isExpanded={isMenuVisible} />
          </div>
          <div {...menuControlProps}>
            <Nav className={styles.items} direction={direction} />
          </div>
          <div className={styles.logo}>
            <Logo />
          </div>
        </div>
      </Container>
    </header>
  );
};
