import type { GatsbyBrowser, GatsbySSR } from 'gatsby';

import type { PageContext, PageData } from './src/config';
import { LocaleProvider, ThemeProvider } from './src/contexts';
import { Layout } from './src/theme';

type WrapPageElement =
  | GatsbyBrowser<PageData, PageContext>['wrapPageElement']
  | GatsbySSR<PageData, PageContext>['wrapPageElement'];

export const wrapPageElement: WrapPageElement = ({ props, element }) => (
  <ThemeProvider>
    <LocaleProvider locale={props.pageContext.locale}>
      {typeof element.type === `function` && `SKIP_LAYOUT` in element.type ? (
        element
      ) : (
        <Layout teaser={element.type.TEASER} {...props}>
          {element}
        </Layout>
      )}
    </LocaleProvider>
  </ThemeProvider>
);
