import type { ComponentProps } from 'react';

import { useAccessibility, useMenu, usePagesByMenu } from '../../hooks';

import { NavItems } from './nav-items';

type Props = {
  className?: string;
  direction?: ComponentProps<typeof NavItems>['direction'];
};

export const Nav = (props: Props) => {
  const pages = usePagesByMenu(`main`);
  const accessibility = useAccessibility();

  const menuProps = useMenu({
    label: accessibility?.menuMainLabel,
  });

  return <NavItems {...menuProps} {...props} pages={pages} />;
};
