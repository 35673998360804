module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"de","start_url":"/","display":"standalone","name":"LLT Website","short_name":"LLT","description":"Alle Informationen und Neuigkeiten zur LLT Innovation Invest GmbH.","theme_color":"#ebebeb","background_color":"#ebebeb","icon":"src/svg/hexagon.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9a644ae54d255a28b093d1cb4d5b0e19"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
