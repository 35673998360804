import { useId } from 'react';
import type { AriaAttributes, HTMLAttributes } from 'react';

import type { Nullable } from '../../types';

export type Props = {
  isExpanded: boolean;
  onToggle: (isExpanded: boolean) => void;
  triggerId?: string;
  label?: Nullable<string>;
  type?: AriaAttributes['aria-haspopup'];
  controlId?: string;
  controlClassName?: string;
  controlExpandedClassName?: string;
  controlBindLabelToButton?: boolean;
};

export type Result = {
  triggerProps: HTMLAttributes<HTMLButtonElement>;
  controlProps: HTMLAttributes<HTMLElement>;
};

export const useDisclosure = ({
  label,
  isExpanded,
  onToggle,
  type = undefined,
  triggerId = undefined,

  controlId = undefined,
  controlClassName = undefined,
  controlExpandedClassName = undefined,
  controlBindLabelToButton = false,
}: Props): Result => {
  const defaultControlId = useId();
  const defaultTriggerId = useId();

  const actualControlId = controlId || defaultControlId;
  const actualTriggerId = triggerId || defaultTriggerId;

  return {
    triggerProps: {
      'aria-controls': actualControlId,
      'aria-expanded': isExpanded,
      'aria-haspopup': type,
      'aria-label': label || undefined,
      id: actualTriggerId,
      title: label || undefined,
      onClick: () => onToggle(!isExpanded),
    },
    controlProps: {
      id: actualControlId,
      className: isExpanded ? controlExpandedClassName : controlClassName,
      'aria-labelledby': controlBindLabelToButton ? actualTriggerId : undefined,
    },
  };
};
