import type { AriaAttributes, ButtonHTMLAttributes } from 'react';

import type { Nullable } from '../../types';

type BaseProps = {
  isDisabled?: boolean;
  descriptionId?: Nullable<AriaAttributes['aria-describedby']>;
};

export type Props = BaseProps &
  (
    | {
        label?: AriaAttributes['aria-labelledby'];
        labelId?: never;
      }
    | {
        label?: never;
        labelId?: AriaAttributes['aria-labelledby'];
      }
  ) &
  (
    | {
        isPressed?: boolean;
        onToggle?: (isPressed: boolean) => void;
        onClick?: never;
      }
    | {
        onClick?: () => void;
        isPressed?: never;
        onToggle?: never;
      }
  );

export type Result = ButtonHTMLAttributes<HTMLButtonElement>;

export const useButton = ({
  label,
  labelId,
  isPressed,
  isDisabled,
  descriptionId,
  onToggle = undefined,
  onClick = () => onToggle?.(!isPressed),
}: Props): Result => {
  return {
    onClick,
    disabled: isDisabled,
    title: label,
    'aria-label': label,
    'aria-pressed': isPressed,
    'aria-disabled': isDisabled,
    'aria-labelledby': labelId,
    'aria-describedby': descriptionId || undefined,
  };
};
